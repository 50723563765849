<template>
  <div id="userLayout">
    <a-layout>
      <a-layout-content class="container">
        <router-view />
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
