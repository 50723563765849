/**
 * 权限定义
 */
const accessEnum = {
  NOT_LOGIN: "notLogin",
  USER: "user",
  ADMIN: "admin",
  VIP: "vip",
};
export default accessEnum;
