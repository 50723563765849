<template>
  <div id="basicLayout">
    <AiAssist />
    <a-layout style="min-height: 100vh; display: flex; flex-direction: column">
      <a-layout-header class="header">
        <global-header />
      </a-layout-header>
      <a-layout-content class="container">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        <a href="https://beian.miit.gov.cn/">晋ICP备2024035154号-4</a>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import AiAssist from "@/components/AiAssist.vue";
import { siteName } from "../assets/globalData";

export default defineComponent({
  methods: {
    siteName() {
      return siteName;
    },
  },
  components: { AiAssist, GlobalHeader },
});
</script>

<style scoped>
#basicLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#basicLayout .header {
  margin-bottom: 10px;
}

#basicLayout .container {
  flex: 1;
  background: linear-gradient(to right, #f7f8fa, #fff);
  margin-bottom: 16px;
  padding: 20px;
  overflow: auto; /* Ensure scroll if content overflows */
}

#basicLayout .footer {
  text-align: center;
  background-color: white;
  width: 100%;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
