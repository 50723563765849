<template>
  <div id="app">
    <!-- 根据路由区分布局-->
    <template v-if="route.path.startsWith('/user')">
      <router-view />
    </template>

    <template v-else>
      <a-watermark :content="store.state.user?.loginUser?.id">
        <BasicLayout />
      </a-watermark>
    </template>
  </div>
</template>

<style>
#app {
  user-select: none;
  background-color: var(--background_color);
  height: 100vh !important;
}
</style>
<script setup lang="ts">
import BasicLayout from "@/layouts/BasicLayout.vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";

const route = useRoute();

/**
 * 全局初始化函数，执行全局单次调用的代码
 */
const diInit = () => {
  console.log("全局执行一次");
};
onMounted(() => {
  diInit();
});
</script>
