import { StoreOptions } from "vuex";

const state = () => ({
  chatMessage: [{ text: "你好，我是你的AI小助手", sender: "other" }],
});

// getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  // 执行异步操作，并且触发mutation的更改
  actions: {},
  // 修改state变量的方法，同步执行
  mutations: {
    updateChatMessage(state, payload) {
      state.chatMessage = payload;
    },
  },
} as StoreOptions<any>;
