// initial state
import { StoreOptions } from "vuex";
import { UserControllerService } from "../../generated";
import AccessEnum from "@/access/accessEnum";

const state = () => ({
  loginUser: {
    userName: "未登录",
  },
});

// getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  // 执行异步操作，并且触发mutation的更改
  actions: {
    async getLoginUser({ commit, state }) {
      // 远程获取登录信息
      const res = await UserControllerService.getLoginUserUsingGet();
      if (res.code === 0) {
        commit("updateUser", res.data);
      } else {
        commit("updateUser", {
          ...state.loginUser,
          userRole: AccessEnum.NOT_LOGIN,
        });
      }
    },
  },
  // 修改state变量的方法，同步执行
  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload;
    },
  },
} as StoreOptions<any>;
