import { RouteRecordRaw } from "vue-router";
import AdminView from "@/views/AdminView.vue";
import AccessEnum from "@/access/accessEnum";
import UserLayout from "@/layouts/UserLayout.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "用户",
    component: UserLayout,
    meta: {
      hideInMenu: true,
    },
    children: [
      {
        path: "login",
        name: "登录",
        component: () => import("@/views/user/UserLoginView.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "首页",
    component: () => import("@/views/home/HomeView.vue"),
  },
  {
    path: "/add/question",
    name: "创建题目",
    component: () => import("@/views/question/AddQuestionView.vue"),
    meta: {
      access: AccessEnum.USER,
    },
  },
  {
    path: "/post",
    name: "文章",
    component: () => import("@/views/home/PostDetail.vue"),
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/update/question",
    name: "更新题目",
    component: () => import("@/views/question/AddQuestionView.vue"),
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/manage/question",
    name: "管理题目",
    component: () => import("@/views/question/ManageQuestionView.vue"),
    meta: {
      access: AccessEnum.ADMIN,
    },
  },
  {
    path: "/my/question",
    name: "我的题目",
    component: () => import("@/views/question/MyQuestionManage.vue"),
    meta: {
      access: AccessEnum.USER,
    },
  },
  {
    path: "/question",
    name: "浏览题目",
    component: () => import("@/views/question/QuestionView.vue"),
  },
  {
    path: "/exercise/question/:id",
    name: "做题",
    component: () => import("@/views/question/ExerciseView.vue"),
    props: true,
    meta: {
      hideInMenu: true,
      access: AccessEnum.USER,
    },
  },
  {
    path: "/about",
    name: "关于",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/noAuth",
    name: "无权限",
    component: () => import("@/views/NoAuthView.vue"),
    meta: {
      hideInMenu: true,
    },
  },
];
