import { createStore } from "vuex";
import user from "@/store/user";
import chatMessage from "@/store/chatMessage";

export default createStore({
  mutations: {},
  actions: {},
  modules: {
    user,
    chatMessage,
  },
});
