<template>
  <a-menu
    style="text-align: center"
    mode="horizontal"
    :selected-keys="selectedKeys"
    @menu-item-click="doMenuClick"
  >
    <a-menu-item key="0" :style="{ padding: 0, marginRight: '38px' }" disabled>
      <div class="title-bar">
        <img class="logo" src="../assets/logo.png" alt="logo" />
        <div class="title">{{ siteName }}</div>
      </div>
    </a-menu-item>
    <a-menu-item v-for="item in visibleRoutes" :key="item.path">
      {{ item.name }}
    </a-menu-item>
    <span style="position: absolute; right: 30px">
      {{ store.state.user?.loginUser?.userName ?? "未登录" }}
    </span>
  </a-menu>
  <div></div>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import { siteName } from "@/assets/globalData";

const router = useRouter();

const route = useRoute();

const selectedKeys = ref(["/"]);

const store = useStore();

const visibleRoutes = computed(() => {
  return routes.filter((item) => {
    if (item.meta?.hideInMenu) {
      return false;
    }
    return checkAccess(
      store.state.user.loginUser,
      item?.meta?.access as string
    );
  });
});
onMounted(() => {
  selectedKeys.value = [route.path ?? "/"];
});

// 路由跳转时更新选中的菜单项
router.afterEach((to) => {
  selectedKeys.value = [to.path];
});
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
</script>

<style scoped>
#globalHeader {
  margin: 0 auto;
}

.title-bar {
  display: flex;
  align-items: center;
}

.title {
  color: #444;
  margin-left: 14px;
}

.logo {
  height: 20px;
}
</style>
