<template>
  <div class="chat-container">
    <div ref="messagesContainer" class="messages">
      <div
        v-for="(message, index) in chatContent"
        :key="index"
        :class="['message', message.sender === 'me' ? 'right' : 'left']"
      >
        <img
          :src="message.sender === 'me' ? myAvatar : otherAvatar"
          class="avatar"
        />
        <div class="message-content">{{ message.text }}</div>
      </div>
      <div class="suggestions">
        <div
          v-for="(suggestion, index) in suggestions"
          :key="index"
          class="suggestion"
          @click="handleSuggestionClick(suggestion, index)"
        >
          {{ suggestion }}
        </div>
      </div>
    </div>
    <div class="input-container">
      <input
        v-model="newMessage"
        @keyup.enter="sendMessageWithStream"
        placeholder="请输入"
      />
      <button v-if="!sending" @click="sendMessageWithStream">发送</button>
      <button v-if="sending" class="sending">ing</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, reactive, ref } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useStore } from "vuex";
import {
  AiAssistChatRequest,
  AiAssistControllerService,
} from "../../generated";

const sending = ref(false);
const chatContent = reactive<any>([]);
const newMessage = ref("");
const otherAvatar = "https://photo.ruojy.top/robot.png"; // 对方的头像
const myAvatar = "https://photo.ruojy.top/userPhoto.png"; // 用户的头像
const store = useStore();
const suggestions = reactive<string[]>([]);

onMounted(() => {
  initializeChatContent();
  addSuggestions();
  down();
});

const initializeChatContent = () => {
  if (store.state.chatMessage?.chatMessage) {
    chatContent.push(...store.state.chatMessage.chatMessage);
  }
};
const allSuggestions = ref<string[]>([
  "什么是暴力搜索算法",
  "我需要一个用于处理日期和时间的类。",
  "写一段简单的java程序",
  "写一段冒泡排序",
  "写一段死循环",
  "什么是类",
  "为什么不能用浮点数表示金额",
  "String为什么要设计成不可变的",
  "final中的代码一定会执行吗",
]);
/**
 * 显示意见
 */
const addSuggestions = () => {
  let randomNumber = 0;
  for (let i = 0; i < 3; i++) {
    randomNumber = Math.floor(Math.random() * allSuggestions.value.length);
    suggestions?.push(allSuggestions.value.at(randomNumber) as string);
  }
};
const chatMessage = ref({
  question: "",
  userId: "",
} as AiAssistChatRequest);
/**
 * 点击气泡
 * @param suggestion
 * @param index
 */
const handleSuggestionClick = (
  suggestion: string | undefined,
  index: number
) => {
  if (sending.value) {
    message.error("正在发送，稍后再试");
    return;
  }
  // Hide the suggestion when clicked
  suggestions.splice(index, 1);
  newMessage.value = suggestion as string;
  // Send the message
  sendMessageWithStream();
  if (suggestions.length === 0) {
    addSuggestions();
  }
};
/**
 * 发送消息
 * @param newMessage
 */
const sendMessage = async () => {
  if (newMessage.value !== "") {
    chatMessage.value.question = newMessage.value;
    newMessage.value = "";
    chatContent.push({ text: chatMessage.value.question, sender: "me" });
    if (!store.state.user?.loginUser?.id) {
      chatContent.push({ text: "请先登录", sender: "other" });
      newMessage.value = "";
      return;
    }
    sending.value = true;
    down();
    try {
      const res = await AiAssistControllerService.coderChatUsingPost(
        chatMessage.value
      );
      if (res.code === 0) {
        chatContent.push({ text: res.data, sender: "other" });
      } else if (res.code === 40101) {
        chatContent.push({ text: res.message + "，会员专属", sender: "other" });
      } else {
        chatContent.push({ text: res.message, sender: "other" });
      }
      store.commit("chatMessage/updateChatMessage", chatContent);
    } catch (error) {
      message.error("发送消息失败");
    } finally {
      sending.value = false;
      down();
    }
  } else {
    message.error("请输入内容");
  }
};
let eventSource = reactive({}) as EventSource;
/**
 * 发送消息（流数据）
 * @param newMessage
 */
const sendMessageWithStream = async () => {
  if (newMessage.value !== "") {
    chatMessage.value.question = newMessage.value;
    newMessage.value = "";
    chatContent.push({ text: chatMessage.value.question, sender: "me" });
    if (!store.state.user?.loginUser?.id) {
      chatContent.push({ text: "请先登录", sender: "other" });
      newMessage.value = "";
      return;
    }
    sending.value = true;
    down();
    // 清理之前的 EventSource 连接
    chatContent.push({ text: "正在思考", sender: "other" });
    // 创建新的 EventSource 连接
    eventSource = new EventSource(
      `https://api.ruojy.top/api/qianfan/chatWithAppStreamBuilder?question=${encodeURIComponent(
        chatMessage.value.question
      )}&questionId=${chatMessage.value.userId}&type=PROGRAM`
    );

    let isReceiving = true;
    let currentTextIndex = 0; // 用于追踪当前显示的位置
    let fullResponse = ""; // 完整的响应文本

    // 定义显示字符的间隔（毫秒）
    const charDisplayInterval = 100; // 增加间隔时间，单位为毫秒
    // 监听消息
    eventSource.onmessage = (event) => {
      const decodedData = decodeURIComponent(escape(atob(event.data)));
      fullResponse += decodedData;

      // 更新占位符气泡的内容
      if (chatContent.length) {
        let currentMessage = chatContent[chatContent.length - 1];
        // 去除刚开始的 正在获取
        if (currentTextIndex === 0) {
          currentMessage.text = "";
        }
        if (currentTextIndex < fullResponse.length) {
          // 逐字显示
          const displayNextChar = () => {
            if (currentTextIndex < fullResponse.length) {
              currentMessage.text += fullResponse[currentTextIndex];
              currentTextIndex++;
              down();
              // 设置下一次字符显示的时间
              setTimeout(displayNextChar, charDisplayInterval);
            }
          };
          // 启动字符显示过程
          displayNextChar();
        } else {
          // 如果消息已经完全显示，停止定时器
          currentMessage.text = fullResponse;
        }
      }
    };

    // 处理错误
    eventSource.onerror = () => {
      if (isReceiving) {
        sending.value = false;
        eventSource.close();
        isReceiving = false;
        console.error("EventSource 连接发生错误");
      }
    };

    // 处理连接打开
    eventSource.onopen = () => {
      console.log("连接已打开");
    };

    // 处理流的结束事件
    eventSource.addEventListener("end", () => {
      if (isReceiving) {
        console.log("end");
        sending.value = false;
        isReceiving = false;
        eventSource.close();
      }
    });
  } else {
    message.error("请输入内容");
  }
};

const messagesContainer = ref<HTMLElement | null>(null);

const down = () => {
  nextTick(() => {
    if (messagesContainer.value) {
      messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
    }
  });
};
</script>

<style scoped>
.chat-container {
  user-select: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  border-radius: 10px;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.messages::-webkit-scrollbar {
  width: 6px;
}

.messages::-webkit-scrollbar-track {
  background: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  visibility: hidden;
}

.messages:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.message {
  display: flex;
  align-items: flex-start;
  max-width: 90%;
  margin: 10px 0;
  border-radius: 10px;
}

.message.left {
  align-self: flex-start;
}

.message.right {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.message-content {
  padding: 10px;
  border-radius: 10px;
}

.message.left .message-content {
  background-color: #f1f1f1;
}

.message.right .message-content {
  background-color: #007bff;
  color: #fff;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.suggestions {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.suggestion {
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  background-color: #e0e0e0;
  cursor: pointer;
}

.suggestion:hover {
  background-color: #d0d0d0;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 8px;
  margin-left: 2px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #007bff; /* Changed hover background color */
  color: #000000; /* Changed hover text color */
}

.input-container button:first-child {
  margin-right: 5px;
}

/*发送中动画*/
.sending {
  width: 10px;
  height: 10px;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
