import store from "@/store";
import router from "@/router";
import AccessEnum from "@/access/accessEnum";
import checkAccess from "@/access/checkAccess";

router.beforeEach(async (to, from, next) => {
  let loginUser = store.state.user.loginUser;
  // 自动登录
  if (!loginUser || !loginUser.userRole) {
    // 等登陆后再执行后续
    await store.dispatch("user/getLoginUser");
    loginUser = store.state.user.loginUser;
  }
  const needAccess = to.meta?.access ?? AccessEnum.NOT_LOGIN;

  // 如果要跳转的页面必须登录
  if (needAccess !== AccessEnum.NOT_LOGIN) {
    // 没有登录，跳转登录
    if (
      !loginUser ||
      !loginUser.userRole ||
      loginUser.userRole === AccessEnum.NOT_LOGIN
    ) {
      next(`/user/login?redirect=${to.fullPath}`);
      // 已登录但权限不足
    } else if (!checkAccess(loginUser, needAccess as string)) {
      next("/noAuth");
    }
  }
  next();
});
