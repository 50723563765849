import AccessEnum from "@/access/accessEnum";

/**
 * 通用权限校验方法
 * 菜单要判断
 * 权限拦截要判断
 * @param loginUser 当前登录用户
 * @param needAccess 需要有的权限
 * return boolean 有无权限
 */
const checkAccess = (loginUser: any, needAccess = AccessEnum.NOT_LOGIN) => {
  // 获取当前用户有的权限
  const loginUserAccess = loginUser?.userRole ?? AccessEnum.NOT_LOGIN;
  if (needAccess === AccessEnum.NOT_LOGIN) {
    return true;
  } else if (needAccess === AccessEnum.USER) {
    // 只需要登录即可，没登陆返回false
    return loginUserAccess !== AccessEnum.NOT_LOGIN;
  } else if (needAccess === AccessEnum.ADMIN) {
    return loginUserAccess === AccessEnum.ADMIN;
  }
};
export default checkAccess;
