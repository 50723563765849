<template>
  <div id="AiAssist">
    <a-popover trigger="click" style="z-index: 1000">
      <a-popover title="Ai助手" style="z-index: 900">
        <div
          class="toggle-button"
          :style="{ top: buttonTop + 'px', left: buttonLeft + 'px' }"
          @mousedown="dragStart"
          @touchstart="dragStart"
          ref="menuToggle"
          @click="aiClick"
        ></div>
        <template #content>
          <p>这里是属于你的Ai小助手</p>
          <p>点击开始</p>
        </template>
      </a-popover>
      <template #title> Ai助手</template>
      <template #content>
        <div style="height: 500px">
          <ChatComponent />
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script>
import ChatComponent from "@/components/ChatComponent.vue";

export default {
  name: "AiAssist",
  components: { ChatComponent },
  data() {
    return {
      buttonTop: 50, // 初始位置
      buttonLeft: 50, // 初始位置
      startX: 0,
      startY: 0,
      dragging: false,
    };
  },
  methods: {
    aiClick() {
      console.log("被点击");
    },
    dragStart(event) {
      // 初始化拖拽状态为 false
      this.dragging = false;
      // 判断是否为触摸事件
      const touch = event.type === "touchstart";
      // 记录起始位置
      this.startX =
        (touch ? event.touches[0].clientX : event.clientX) - this.buttonLeft;
      this.startY =
        (touch ? event.touches[0].clientY : event.clientY) - this.buttonTop;
      // 添加移动和结束事件监听器
      document.addEventListener(touch ? "touchmove" : "mousemove", this.drag, {
        passive: false,
      });
      document.addEventListener(touch ? "touchend" : "mouseup", this.dragEnd);
      if (touch) {
        // 在触摸设备上，防止默认的下拉刷新
        document.addEventListener("touchmove", this.preventPullToRefresh, {
          passive: false,
        });
      }
    },
    drag(event) {
      // 设置拖拽状态为 true
      if (!this.dragging) {
        this.dragging = true;
      }
      // 计算新位置
      const touch = event.type === "touchmove";
      this.buttonLeft =
        (touch ? event.touches[0].clientX : event.clientX) - this.startX;
      this.buttonTop =
        (touch ? event.touches[0].clientY : event.clientY) - this.startY;
    },
    dragEnd(event) {
      // 移除移动和结束事件监听器
      const touch = event.type === "touchend";
      document.removeEventListener(
        touch ? "touchmove" : "mousemove",
        this.drag
      );
      document.removeEventListener(
        touch ? "touchend" : "mouseup",
        this.dragEnd
      );
      if (touch) {
        // 移除防止下拉刷新的事件监听器
        document.removeEventListener("touchmove", this.preventPullToRefresh);
      }

      // 只有在未发生拖拽时才触发点击事件
      if (this.dragging) {
        this.$refs.menuToggle.click();
      }
      // 重置拖拽状态
      this.dragging = false;
    },
    // 防止默认的下拉刷新行为
    preventPullToRefresh(event) {
      if (this.dragging) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
#AiAssist {
  z-index: 1000;
  user-select: none;
}

.toggle-button {
  position: absolute; /* 绝对定位以便拖拽 */
  cursor: move; /* 显示移动光标 */
  width: 120px;
  height: 59px;
  background: url("../../public/img.png") no-repeat;
  line-height: 30px;
  text-align: center;
  user-select: none; /* 防止文本被选中 */
}
</style>
